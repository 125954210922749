import * as React from "react";
import TwoColumn from "../components/layout/TwoColumn";
import DemoDashboardMetrics from "./DemoDashboardMetrics";
import DemoTopics from "./DemoTopics";
import DemoSocialStream from "./DemoSocialStream";
import DemoSummaryStream from "./DemoSummaryStream";

const DemoDashboardViewPage = (props) => {

    const getDashboard = () => {

        return (
            <div>
                <div className={"text-2xl px-6 pb-2 pt-4"}>
                    Caffelli Discord
                </div>
            </div>
        )


    }

    const getDashboardHeading = () => {
            return (
                <div>
                    Caffelli Discord
                </div>
            )
    }

    return (
        <>
            <div className={"text-2xl px-6 pb-2 pt-4"}>
                {getDashboardHeading()}
            </div>
            <TwoColumn>
                <div>
                    <DemoDashboardMetrics />
                    <DemoSocialStream />
                </div>
                <div>
                    <DemoTopics />
                    <DemoSummaryStream />
                </div>
            </TwoColumn>
        </>
    );
};

export default DemoDashboardViewPage;
