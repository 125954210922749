import {TwitterTopicMetric} from "../models/twitter.model";

export interface PostTopic {
    id: number;
    sentiment?: string;
}

export interface Post {
    handle: string;
    text: string;
    msg_id: string;
    date: string;
    topics: PostTopic[];
}

export interface Summary {
    startDate: string;
    endDate: string;
    summary: string;
    recs: string;
}

export interface Topic {
    label: string;
    id: number;
    arcColor: string;
    bgColor: string;
    sentiment: boolean;
}

export interface TopicSummary {
    topic_id: number;
    insights: string;
}

export type DiscordTopicMetric = {
    // topic id
    id: number;
    // mentions
    m: number;
    // negative
    neg: number;
    pos: number;
    neu: number;
}

export type DiscordMetric = {
    // 't' is the date when the metrics were reported, represented as a string.
    t: string;

    // 'm' represents the total number of mentions.
    m: number;

    tps: DiscordTopicMetric[] | null;
}

export const TopicMap: {[key: number]: Topic;} = {
    1: {
        label: 'CPU',
        arcColor: "rgb(239 68 68)",
        bgColor: "rgb(254 202 202)",
        id: 1,
        sentiment: false
    },
    2: {
        label: 'GPU',
        arcColor: "rgb(59 130 246)",
        bgColor: "rgb(191 219 254)",
        id: 2,
        sentiment: false
    },
    3: {
        label: 'Motherboard',
        arcColor: "rgb(20 184 166)",
        bgColor: "rgb(153 246 228)",
        id: 3,
        sentiment: false
    },
    4: {
        label: 'RAM',
        arcColor: "rgb(139 92 246)",
        bgColor: "rgb(221 214 254)",
        id: 4,
        sentiment: false
    },
    5: {
        label: 'Power Supply',
        arcColor: "rgb(132 204 22)",
        bgColor: "rgb(217 249 157)",
        id: 5,
        sentiment: false
    },
    6: {
        label: 'Storage',
        arcColor: "rgb(234,88,12)",
        bgColor: "rgb(254,215,170)",
        id: 6,
        sentiment: false
    },
    7: {
        label: 'Intel CPUs',
        arcColor: "rgb(59 130 246)",
        bgColor: "rgb(191 219 254)",
        id: 7,
        sentiment: true
    },
    8: {
        label: 'AMD CPUs',
        arcColor: "rgb(20 184 166)",
        bgColor: "rgb(153 246 228)",
        id: 8,
        sentiment: true
    },
    9: {
        label: 'Intel GPUs',
        arcColor: "rgb(139 92 246)",
        bgColor: "rgb(221 214 254)",
        id: 9,
        sentiment: true
    },
    10: {
        label: 'NVIDIA GPUs',
        arcColor: "rgb(132 204 22)",
        bgColor: "rgb(217 249 157)",
        id: 10,
        sentiment: true
    },
    11: {
        label: 'AMD GPUs',
        arcColor: "rgb(234,88,12)",
        bgColor: "rgb(254,215,170)",
        id: 11,
        sentiment: true
    }
}
