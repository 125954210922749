import React, {ChangeEventHandler, Fragment, ReactNode, useEffect, useState,} from "react";
import TileRounded from "../components/layout/TileRounded";
import TileBgNone from "../components/layout/TileBgNone";
import {CalendarIcon, DownloadIcon,} from "@heroicons/react/outline";
import {Popover, Transition} from "@headlessui/react";
import {format, isValid, parse} from "date-fns";
import {DayPicker, SelectSingleEventHandler} from "react-day-picker";
import "react-day-picker/dist/style.css";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import {getDateFromApiString, SentimentType} from "../util/api";
import {Post, TopicMap} from "./models";
import {posts} from "./data";


const sortedPosts: Post[] = posts.sort((a, b) => {
    const dateA = getDateFromApiString(a.date, 'America/New_York').toJSDate().getTime();
    const dateB = getDateFromApiString(b.date, 'America/New_York').toJSDate().getTime();
    return dateB - dateA; // Descending order
});

const DemoSocialStream = () => {
    const [content, setContent] = useState<Post[]>([]);
    const [dateApi, setDateApi] = useState<string | null>(null);
    const [offset, setOffset] = useState<number | null>(null);
    const [topicId, setTopicId] = useState<number>(0);
    const [sentiment, setSentiment] = useState<SentimentType>(SentimentType.ANY);

    const [selectedDate, setSelectedDate] = useState<Date>();
    const [inputDateValue, setInputDateValue] = useState<string>("");
    const [isDateError, setIsDateError] = useState<boolean>(false);
    const [isRecentContent, setIsRecentContent] = useState<boolean>(true)



    useEffect( () => {
        let filteredPosts = [...sortedPosts]
        setIsRecentContent(true)
        if (dateApi) {
            filteredPosts = filteredPosts.filter(post => {
                const date = getDateFromApiString(post.date, 'America/New_York').toJSDate();
                const dayKey = date.toISOString().split('T')[0];
                return dayKey === dateApi
            });
            setIsRecentContent(false)
        } else {
            setIsRecentContent(true)
        }
        if (topicId) {
            if (SentimentType.ANY === sentiment) {
                filteredPosts = filteredPosts.filter(post => post.topics.find(t => t.id === topicId));
            } else {
                filteredPosts = filteredPosts.filter(
                    post => post.topics.find(
                        t => {
                            if (t.id === topicId) {
                                return t.sentiment === sentiment;
                            }
                            return false
                        }
                    )
                );
            }
        }
        const mostRecent = filteredPosts.slice(0, 20)
        setContent(mostRecent)
    }, [topicId, sentiment, dateApi])

    const loadMoreTweets = () => {
        if (dateApi === null) {
            return;
        }
        // execute(
        //     dashboardId,
        //     dateApi,
        //     offset,
        //     topicId === '0' ? null : topicId,
        //     sentiment != SentimentType.ANY ? sentiment : null
        // ).then((response) => {
        //     if (response) {
        //         setContent([...content, ...response.tweets]);
        //     }
        //     if (response?.isNext) {
        //         setIsTweetsLoaded(true);
        //         setOffset(response?.nextOffset ?? null);
        //     } else {
        //         setOffset(null);
        //     }
        // });
    };

    const displayPost = (post: Post, id) => {
        return (
            <div key={id} className={"border-b-2 border-gray-100 pb-4 pt-4"}>
                <div className={"font-bold font-xl"}>
                    <h1>{post.handle}</h1>
                </div>
                <div className={"text-gray-500 text-sm pt-1 pb-2"}>
                    {format(getDateFromApiString(post.date, 'America/New_York').toJSDate(), "y-MM-dd h:mm aa")}
                </div>
                <div className={"text-gray-800 font-md p-2"}>
                    {post.text}
                </div>
            </div>
        )
    }

    const onFilterChange = () => {
        setOffset(null)
    }

    const getToggles = () => {
        if (isRecentContent) {
            return null;
        }
        return (
            <div className={"flex"}>
                <div className={"flex-grow"}>
                    <button
                        type={"button"}
                        className="inline-flex items-center px-4 py-2  border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => resetDate()}
                    >
                        Reset Date
                    </button>
                </div>
            </div>
        );
    };

    const noTweetsJsx = (message: string) => {
        return (
            <div
                className={
                    "m-auto pt-16 pb-16 text-center text-xl text-semi-bold text-gray-400"
                }
            >
                {message}
            </div>
        );
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setInputDateValue(e.currentTarget.value);
        if (e.currentTarget.value.length < 10) {
            setSelectedDate(undefined);
            return;
        }
        const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
        if (isValid(date)) {
            setIsDateError(false);
            setSelectedDate(date);
            setDateApi(e.currentTarget.value);
            setIsRecentContent(false);
        } else {
            setIsDateError(true);
            setSelectedDate(undefined);
        }
    };

    const handleDaySelect = (
        closeCallBack: () => void
    ): SelectSingleEventHandler => {
        return (date, selectedDay, activeModifiers, e) => {
            setSelectedDate(date);
            if (date) {
                const dateString = format(date, "y-MM-dd");
                setInputDateValue(dateString);
                setIsDateError(false);
                setDateApi(dateString);
                setIsRecentContent(false);
                closeCallBack();
            } else {
                setInputDateValue("");
            }
        };
    };

    const resetDate = () => {
        setInputDateValue("");
        setSelectedDate(undefined);
        setIsDateError(false);
        setIsRecentContent(true);
        setDateApi(null);
        setOffset(null);
    };

    const getCalendar = () => {
        let inputClass =
            "shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md";
        if (isDateError) {
            inputClass =
                "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
        }
        return (
            <div className={"flex float-right"}>
                <div className={"flex-1 mt-1"}>
                    <label htmlFor="date" className="sr-only">
                        Enter Date in YYYY-MM-DD format
                    </label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                            type="text"
                            name="date"
                            id="date"
                            className={inputClass}
                            placeholder={format(new Date(), "y-MM-dd")}
                            value={inputDateValue}
                            onChange={handleInputChange}
                            aria-invalid="true"
                            aria-describedby={isDateError ? "social-stream-date-error" : undefined}
                        />
                        {isDateError ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <ExclamationCircleIcon
                                    className="h-5 w-5 text-red-500"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : null}
                    </div>
                    {isDateError ? (
                        <p className="mt-1 text-sm text-red-600 absolute" id="social-stream-date-error">
                            Invalid date (YYYY-MM-DD format)
                        </p>
                    ) : null}
                </div>
                <div>
                    <Popover className="relative">
                        {({ open, close }) => (
                            <>
                                <Popover.Button
                                    className={`
                ${open ? "" : "text-opacity-90"}
                mb-0 pb-0 focus:outline-none group inline-flex items-center rounded-md px-3 py-2 text-base font-medium text-white hover:text-opacity-100 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                                >
                                    <CalendarIcon
                                        className={
                                            "h-9 w-9 text-blue-600 rounded-md focus:ring-blue-500 focus:border-blue-500"
                                        }
                                    />
                                </Popover.Button>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0 translate-y-1"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-1"
                                >
                                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 -translate-x-full xl:-translate-x-1/2 transform px-4 sm:px-0">
                                        <div className="overflow-hidden bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                            <DayPicker
                                                initialFocus={open}
                                                mode="single"
                                                defaultMonth={selectedDate}
                                                selected={selectedDate}
                                                onSelect={handleDaySelect(close)}
                                            />
                                        </div>
                                    </Popover.Panel>
                                </Transition>
                            </>
                        )}
                    </Popover>
                </div>
            </div>
        );
    };

    const getLoadMoreButton = () => {
        if (isRecentContent) {
            return null;
        }
        return (
            <div>
                <button
                    onClick={loadMoreTweets}
                    className={`${
                        "text-gray-700 bg-white hover:bg-gray-50"
                    }
             inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                >
(
                        <DownloadIcon className={"w-6 h-5 text-gray-400"} />
                    <div className={"inline-block pl-1"}>Load More</div>
                </button>
            </div>
        );
    };

    const getTweetRender = () => {
        if (isRecentContent) {
            const defaultTweets: ReactNode[] = [];
            content.forEach((tweetData, index) => {
                defaultTweets.push(
                    displayPost(tweetData, index)
                );
            });
            return <div className={"pb-4"}>{defaultTweets}</div>;
        } else {
            if (content.length === 0) {
                return noTweetsJsx(`no data found for date ${dateApi}`);
            }
            const apiTweets: ReactNode[] = [];
            content.forEach((tweetData, index) => {
                apiTweets.push(
                    displayPost(tweetData, index)
                );
            });
            return <div className={"pb-4"}>{apiTweets}</div>;
        }
    };

    const getSentimentFilter = () => {
        if (!topicId || !TopicMap[topicId].sentiment) {
            return null;
        }
        return (
            <>
                <label
                    htmlFor={'social-stream-sentiment-filter'}
                    className="block text-sm text-gray-500"
                >
                    Sentiment
                </label>
                <select
                    defaultValue={sentiment}
                    onChange={(e) => {
                        onFilterChange()
                        setSentiment(e.target.value as SentimentType)
                    }}
                    name={'social-stream-sentiment-filter'}
                    id={'social-stream-sentiment-filter'}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md"
                >
                    <option value={SentimentType.ANY}>- Any -</option>
                    <option value={SentimentType.POSITIVE}>Positive</option>
                    <option value={SentimentType.NEGATIVE}>Negative</option>
                    <option value={SentimentType.NEUTRAL}>Neutral</option>
                </select>
            </>
        )
    }

    const getTopicOptions = () => {
        const options: JSX.Element[] = []
        options.push(
            <option key={'0'} value={'0'}>- Any -</option>
        )
        const topics = Object.values(TopicMap)
        if (!topics || topics.length === 0) {
            return options
        }
        for (const topic of topics) {
            options.push(
                <option key={`${topic.id}`} value={`${topic.id}`}>{topic.label}</option>
            )
        }
        return options
    }

    const getTopicFilter = () => {
        return (
            <>
                <label
                    htmlFor={'social-stream-sentiment-filter'}
                    className="block text-sm text-gray-500"
                >
                    Topic
                </label>
                <select
                    defaultValue={`${topicId}`}
                    onChange={(e) => {
                        onFilterChange()
                        setTopicId(parseInt(e.target.value))
                        setSentiment(SentimentType.ANY)
                    }}
                    name={'social-stream-topic-filter'}
                    id={'social-stream-topic-filter'}
                    className="shadow-sm mt-1 block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md max-w-[230px]"
                >

                    {getTopicOptions()}
                </select>
            </>
        )
    }

    return (
        <TileBgNone>
            <div className={"flex pt-1 pb-5"}>
                <div className={"flex-grow pt-9"}>
                    <h2 className={"text-xl"}>Feed</h2>
                </div>
                <div className={"flex"}>
                    <div className={"p-2"}>
                        {getSentimentFilter()}
                    </div>
                    <div className={"p-2"}>
                        {getTopicFilter()}
                    </div>
                    <div className={"pt-6"}>
                        {getCalendar()}
                    </div>
                </div>
            </div>
            <TileRounded
                classArray={["max-h-[915px]", "overflow-auto"]}
                tileType={"inner"}
            >
                <div>{getToggles()}</div>
                <div className={"min-h-[250px]"}>{getTweetRender()}</div>
            </TileRounded>
        </TileBgNone>
    );
};

export default DemoSocialStream;
